import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Accordion from 'react-bootstrap/Accordion'

const DocumentsPage = () => {
  return (
    <Layout activePage="documents">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
        Образцы документов
      </h2>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Документы для граждан
          </Accordion.Header>
          <Accordion.Body>
            <p className="p-style3"><a className="a-style" href="/doc/brachny-dogovor.pdf" target="_blank">Брачный договор</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/darenie-videlennoi-doli.pdf" target="_blank">Дарение выделенной доли из доли в квартире</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/darenie-doli-v-kvartire.pdf" target="_blank">Дарение доли в квартире</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/dogovor-kupli.pdf" target="_blank">Договор купли</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/dogovor-kupli-prodazhi.pdf" target="_blank">Договор купли-продажи квартиры 2013</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/dogovor-prodazhi-dachi.pdf" target="_blank">Договор продажи дачного участка</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/zayavlenie-v-sud.pdf" target="_blank">Заявление в суд мировое соглашение</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/razdel-imushestva.pdf" target="_blank">Раздел имущества между супругами</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/raspiska-v-poluchenii-alimentov.pdf" target="_blank">Расписка в получении алиментов за текущий период</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglashenie-o-razdele-nasledstva.pdf" target="_blank">Соглашение о разделе наследства</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglasheni-ob-oplate-alimentov.pdf" target="_blank">Соглашение об оплате алиментов</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglashenie-ob-opredelenii-doli.pdf" target="_blank">Соглашение об определении долей в квартире</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglashenie-ob-uplate-alimentov-imushestvo.pdf" target="_blank">Соглашение об уплате алиментов путём предоставления имущества</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglashenie-razdel-imushestva.pdf" target="_blank">Соглашение раздел имущества супругов</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/soglashenie-roditeley.pdf" target="_blank">Соглашение родителей о проживании ребенка</a></p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Документы для юридических лиц и предпринимателей
          </Accordion.Header>
          <Accordion.Body>
            <p className="p-style3"><a className="a-style" href="/doc/vklad-novogo-uchastnika.pdf" target="_blank">Вклад нового участника ООО</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/dogovor-dareniya-doli.pdf" target="_blank">Договор дарения доли</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/dogovor-prodazhi-chasti-doli.pdf" target="_blank">Договор продажи части доли ООО между юридическими лицами</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/prodazha-doli-fiz-lica.pdf" target="_blank">Продажа доли между физическими лицами</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/prodazha-doli-ur-lica.pdf" target="_blank">Продажа части доли между юридическими лицами</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/prodazhi-chasti-doli.pdf" target="_blank">Продажа части доли</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/protokol-uvelicheniya-uk.pdf" target="_blank">Протокол увеличения уставного капитала</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/titulniy-list-ustava.pdf" target="_blank">Титульный лист устава</a></p>
            <p className="p-style3"><a className="a-style" href="/doc/ustav.pdf" target="_blank">Устав</a></p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Layout>
  )
}

export default DocumentsPage
